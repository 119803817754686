import { ButtonProps, Button as KendoButton } from '@progress/kendo-react-buttons';

interface Props extends ButtonProps {
  loading?: boolean;
}
const Button = ({ loading, ...props }: Props) => <KendoButton {...(loading ? { icon: 'loading' } : {})} {...props} />;

Button.defaultProps = {
  loading: false,
};

export default Button;
